import { useEffect } from 'react'
import { useRouter } from 'next/router'
import adminRoutes from '@docpace/shared-react-routes/adminRoutes'

export function AdminIndex() {
    const router = useRouter()
    useEffect(() => {
        router?.replace(adminRoutes.Home.href())
    }, [router])
    return <></>
}

export default AdminIndex
